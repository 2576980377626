<template>
    <div class="myQuery"></div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
        };
    },
    watch: {},
    computed: {},
    methods: {},
    created() {},
    mounted() {},
};
</script>
<style lang="stylus" scoped>
.myQuery
    height 100%
    overflow hidden
    background url(./../images/myQuery.png) no-repeat
    background-size 100% 100%
</style>